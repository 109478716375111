import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import bottomSheetStyles from '../../BottomSheet.module.scss';
import styles from './Drawer.module.scss';

type Props = {
  contentUrl: string | undefined;
};

const Drawer = ({ contentUrl }: Props) => {
  const sessionId = useAppSelector((state) => state.connection.sessionId);
  const [wasOpen, setWasOpen] = useState(false);

  useEffect(() => {
    setWasOpen((open) => open || !!contentUrl);
  }, [contentUrl]);

  const url = useMemo(() => {
    if (contentUrl && sessionId) {
      const url = new URL(contentUrl, document.baseURI);
      url.searchParams.set('sessionId', sessionId);
      return url.toString();
    } else {
      return undefined;
    }
  }, [contentUrl, sessionId]);

  return (
    <div
      className={classNames(styles.container, bottomSheetStyles.bottomSheet, {
        [bottomSheetStyles.bottomSheetOpen]: !!contentUrl,
        [bottomSheetStyles.bottomSheetClose]: wasOpen && !contentUrl,
      })}
    >
      {url && <iframe title="Drawer" src={url} className={styles.iframe} />}
    </div>
  );
};

export default Drawer;
