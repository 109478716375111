import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import globalStyles from '../../../../Global.module.scss';
import useTranslation from '../../../../locales/useTranslation';
import bottomSheetStyles from '../../../BottomSheet.module.scss';
import BotBubble from './BotBubble';
import GuestBubble from './GuestBubble';
import styles from './RateInput.module.scss';
import Stars from './Stars';

type Props = {
  visible: boolean;
  rating: number;
  onClose: () => void;
  onRate: (value: number, feedback: string) => void;
};

const RateOverlay = ({ visible, rating, onRate, onClose }: Props) => {
  const translate = useTranslation();
  const [tempRating, setTempRating] = useState(rating);
  const [rateText, setRateText] = useState<string | undefined>(undefined);
  const [internalVisible, setInternalVisible] = useState<boolean | undefined>(
    undefined
  );
  const [submitValue, setSubmitValue] = useState<{
    submitted: boolean;
    feedback: string;
  }>({ submitted: false, feedback: '' });
  const [showSecondBotBubble, setShowSecondBotBubble] = useState(false);

  const guestFeedbackInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (visible) {
      setSubmitValue({ submitted: false, feedback: '' });
      setInternalVisible(visible);
    } else if (internalVisible === true) {
      setInternalVisible(false);
    }
  }, [visible, internalVisible]);

  useEffect(() => {
    setTempRating(rating);
  }, [rating]);

  useEffect(() => {
    const text = translate(
      `conversation-rating.overlay.bot-message.rate-feedback.value-${tempRating}`
    );
    setRateText(!!text?.length ? text : undefined);
  }, [tempRating, translate]);

  useEffect(() => {
    if (submitValue.submitted) {
      setTimeout(
        () => setShowSecondBotBubble(true),
        submitValue.feedback.length ? 500 : 0
      );
    } else {
      setShowSecondBotBubble(false);
    }
  }, [submitValue]);

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const feedback = guestFeedbackInputRef.current?.value?.trim() || '';
    onRate(tempRating, feedback);
    setSubmitValue({ submitted: true, feedback });
  };

  return (
    <div
      className={classNames(
        styles.rateOverlay,
        bottomSheetStyles.bottomSheet,
        'chat__conversationRating__overlay',
        {
          [bottomSheetStyles.bottomSheetOpen]: internalVisible === true,
          [bottomSheetStyles.bottomSheetClose]: internalVisible === false,
        }
      )}
    >
      <form onSubmit={handleOnSubmit}>
        <div
          className={classNames(
            styles.body,
            'chat__conversationRating__overlay__body'
          )}
        >
          <div>
            <h2>{translate('conversation-rating.overlay.title')}</h2>
            <div className={classNames(styles.starsDescriptionContainer)}>
              <Stars
                value={tempRating}
                size="large"
                readonly={submitValue.submitted}
                onChange={setTempRating}
              />
              <div className={classNames(styles.starsDescription)}>
                <div>
                  {translate('conversation-rating.overlay.star.very-bad')}
                </div>
                <div>
                  {translate('conversation-rating.overlay.star.very-good')}
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.bubbles)}>
            {visible && rateText && (
              <>
                <BotBubble
                  isFirstMessageBySender
                  isLastMessageBySender={
                    !showSecondBotBubble || !!submitValue.feedback.length
                  }
                >
                  <div>{rateText}</div>
                </BotBubble>

                {!!submitValue.feedback.length && (
                  <GuestBubble>{submitValue.feedback}</GuestBubble>
                )}

                {showSecondBotBubble && (
                  <BotBubble
                    isFirstMessageBySender={!!submitValue.feedback.length}
                    isLastMessageBySender
                  >
                    <div>
                      {translate(
                        'conversation-rating.overlay.bot-message.submit-feedback'
                      )}
                    </div>
                  </BotBubble>
                )}
              </>
            )}
          </div>
        </div>

        <div
          className={classNames(
            styles.footer,
            'chat__conversationRating__overlay__footer'
          )}
        >
          {!submitValue.submitted && (
            <input
              ref={guestFeedbackInputRef}
              placeholder={translate(
                'conversation-rating.overlay.guest-feedback.input-placeholder'
              )}
            />
          )}
          <div
            className={classNames(
              styles.actions,
              'chat__conversationRating__overlay__footer__actions'
            )}
          >
            {submitValue.submitted ? (
              <button
                type="button"
                className={classNames(
                  globalStyles.button,
                  globalStyles.buttonPrimary
                )}
                onClick={onClose}
              >
                {translate('conversation-rating.overlay.action.close')}
              </button>
            ) : (
              <button
                type="submit"
                className={classNames(
                  globalStyles.button,
                  globalStyles.buttonPrimary
                )}
              >
                {translate('conversation-rating.overlay.action.submit')}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default RateOverlay;
