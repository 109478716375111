import classNames from 'classnames';
import messageStyle from '../../../Message.module.scss';
import MarkdownText from '../MarkdownText';

type Props = {
  markdown: string;
  className: string;
  onLinkClick: (href: string) => void;
};

const MarkdownMessage = ({ markdown, className, onLinkClick }: Props) => (
  <div className={classNames(messageStyle.bubbleContent, className)}>
    <MarkdownText markdown={markdown} onLinkClick={onLinkClick} />
  </div>
);

export default MarkdownMessage;
