import { useState } from 'react';
import { ConversationRatingState } from '../../../../types';
import CallToAction from './CallToAction';
import RateOverlay from './RateOverlay';

interface Props {
  conversationRating: ConversationRatingState;
  numberOfMessagesFromGuest: number;
  showRatingOverlay: boolean;
  setShowRatingOverlay: (show: boolean) => void;
  onRate: (rating: number, feedback: string) => void;
}

const ConversationRating = ({
  conversationRating: { enabled, rating },
  numberOfMessagesFromGuest,
  showRatingOverlay,
  setShowRatingOverlay,
  onRate,
}: Props) => {
  const [tempRating, setTempRating] = useState(0);

  const handleOnCallToActionChange = (rating: number) => {
    setTempRating(rating);
    setShowRatingOverlay(true);
  };

  const handleOnCloseOverlay = () => setShowRatingOverlay(false);

  const showCallToAction = !rating && numberOfMessagesFromGuest >= 1;
  return enabled ? (
    <>
      <CallToAction
        visible={showCallToAction}
        onRate={handleOnCallToActionChange}
      />
      <RateOverlay
        visible={showRatingOverlay}
        rating={tempRating}
        onRate={onRate}
        onClose={handleOnCloseOverlay}
      />
    </>
  ) : null;
};

export default ConversationRating;
