import Linkify from 'react-linkify';
import styles from './Text.module.scss';

type Props = {
  text: string;
  onLinkClick: (href: string) => void;
};

const Text = ({ text, onLinkClick }: Props) => {
  const handleOnLinkClick = (href: string) => (event: any) => {
    event.preventDefault();
    if (href) {
      onLinkClick(href);
    }
    return false;
  };

  const componentDecorator = (
    decoratedHref: string,
    decoratedText: string,
    key: number
  ) => (
    <a key={key} onClick={handleOnLinkClick(decoratedHref)} role="button">
      {decoratedText}
    </a>
  );

  return (
    <div>
      {text.split('\n').map((item, key) => (
        <span key={key} className={styles.text}>
          <Linkify componentDecorator={componentDecorator}>{item}</Linkify>
          <br />
        </span>
      ))}
    </div>
  );
};

export default Text;
