import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StartScreenState } from '../types';

const initialState: StartScreenState = {
  useStartScreen: undefined,
  formValues: {},
};

const startPageSlice = createSlice({
  name: 'start-screen',
  initialState,
  reducers: {
    enableStartScreen(state) {
      state.useStartScreen = true;
    },

    disableStartScreen(state) {
      state.useStartScreen = false;
    },

    showStartScreen(state) {
      state.startScreenStatus = 'VISIBLE';
      state.formValues = {};
    },

    startChat(
      state,
      action: PayloadAction<Record<string, string | undefined>>
    ) {
      state.startScreenStatus = 'HIDDEN';
      state.formValues = action.payload;
    },
  },
});

export const {
  enableStartScreen,
  disableStartScreen,
  startChat,
  showStartScreen,
} = startPageSlice.actions;

export default startPageSlice.reducer;
