import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const useSetDocumentLanguageEffect = () => {
  const intl = useIntl();

  useEffect(() => {
    window.document.documentElement.lang = intl.locale;
  }, [intl.locale]);
};

export default useSetDocumentLanguageEffect;
