import classNames from 'classnames';
import React from 'react';
import useTranslation from '../../../../../locales/useTranslation';
import styles from './ProblemReport.module.scss';

interface Props {
  reported: boolean | undefined;
  onReport: (problem: string | null) => void;
}

const ProblemReport = ({ reported, onReport }: Props) => {
  const translate = useTranslation();

  const handleOnReport = (problem: string | null) => () => onReport(problem);
  const handleOnReportKeyDown =
    (problem: string | null) =>
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        onReport(problem);
      }
    };

  return (
    <div className={styles.problemReport}>
      {reported ? (
        <div
          role="button"
          onClick={handleOnReport(null)}
          onKeyDown={handleOnReportKeyDown(null)}
          tabIndex={0}
          className={classNames(
            styles.problemReportIcon,
            'chat__problemReportIcon',
            'chat_problemReportIcon',
            styles.problemReportIconActive,
            'chat__problemReportIcon-active',
            'chat_problemReportIcon-active'
          )}
          aria-label={translate('problem-report.action.unmark')}
          data-title={translate('problem-report.action.unmark')}
        >
          <img src="problem-report-active.svg" alt="Report" />
        </div>
      ) : (
        <div
          role="button"
          onClick={handleOnReport('unspecified')}
          onKeyDown={handleOnReportKeyDown('unspecified')}
          tabIndex={0}
          className={classNames(
            styles.problemReportIcon,
            'chat_problemReportIcon',
            styles.problemReportIconInactive,
            'chat_problemReportIcon-inactive'
          )}
          aria-label={translate('problem-report.action.mark')}
          data-title={translate('problem-report.action.mark')}
        >
          <img src="problem-report-inactive.svg" alt="Report" />
        </div>
      )}
    </div>
  );
};

export default ProblemReport;
