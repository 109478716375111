import { Button } from '@botfabrik/engine-domain';
import classNames from 'classnames';
import Body from './Body';
import styles from './DetailMessage.module.scss';
import Image from './Image';

type Props = {
  title?: string;
  text?: string;
  subtitle: string;
  imageUrl?: string;
  itemUrl?: string;
  buttons: Button[];
  onButtonClick: (button: Button) => void;
  onLinkClick: (href: string) => void;
};

const DetailMessage = ({
  title,
  text,
  subtitle,
  imageUrl,
  itemUrl,
  buttons,
  onButtonClick,
  onLinkClick,
}: Props) => {
  const hasBody =
    !!title?.length ||
    !!text?.length ||
    !!subtitle?.length ||
    !!buttons?.length;
  return (
    <div className={classNames(styles.detailMessage)}>
      <Image
        hasBody={hasBody}
        imageUrl={imageUrl}
        itemUrl={itemUrl}
        alt={title || subtitle}
      />
      {hasBody && (
        <Body
          title={title}
          text={text}
          subtitle={subtitle}
          buttons={buttons}
          onLinkClick={onLinkClick}
          onButtonClick={onButtonClick}
        />
      )}
    </div>
  );
};

export default DetailMessage;
