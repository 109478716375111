import { IntlProvider } from 'react-intl';
import styles from './App.module.scss';
import getClientName from './getClientName';
import { useAppSelector } from './hooks';
import RootScene from './scenes';

const App = () => {
  const clientName = getClientName();
  const locale = useAppSelector((state) => state.intl.locale);
  const message = useAppSelector((state) => state.intl.messages);

  return (
    <IntlProvider locale={locale} messages={message}>
      <div className={styles.app}>
        <RootScene clientName={clientName} />
      </div>
    </IntlProvider>
  );
};

export default App;
