import { AnyAction } from 'redux';
import * as ConnectionActions from '../actions/connectionActions';
import { ConnectionState, ConnectionStateEnum } from '../types';

const backendConnectionReducer = (
  state = initialState,
  action: AnyAction
): ConnectionState => {
  switch (action.type) {
    case ConnectionActions.CONNECTING:
      return Object.assign({}, state, {
        connectionState: ConnectionStateEnum.CONNECTING,
      });

    case ConnectionActions.CONNECTED:
      return Object.assign({}, state, {
        connectionState: ConnectionStateEnum.CONNECTED,
      });

    case ConnectionActions.DISCONNECTED:
      return Object.assign({}, state, {
        connectionState: ConnectionStateEnum.DISCONNECTED,
      });

    case ConnectionActions.RECONNECTED:
      return Object.assign({}, state, {
        connectionState: ConnectionStateEnum.RECONNECTED,
      });

    case ConnectionActions.RESTORE_SESSION_ID:
      return Object.assign({}, state, {
        sessionId: action.sessionId,
      });

    case ConnectionActions.START:
      return Object.assign({}, state, {
        sessionId: action.payload.sessionId,
        userId: action.payload.userId,
      });

    case ConnectionActions.TERMINATE_SESSION:
      return Object.assign({}, state, {
        sessionId: undefined,
      });

    default:
      return state;
  }
};

const initialState: ConnectionState = {
  connectionState: ConnectionStateEnum.DISCONNECTED,
  sessionId: undefined,
  userId: undefined,
};

export default backendConnectionReducer;
