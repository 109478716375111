import classNames from 'classnames';
import styles from './DetailMessage.module.scss';

type Props = {
  alt: string;
  imageUrl?: string;
  itemUrl?: string;
  hasBody: boolean;
};

const Image = ({ alt, imageUrl, itemUrl, hasBody }: Props) =>
  imageUrl ? (
    itemUrl ? (
      itemUrl.indexOf('youtube.com') > -1 ? (
        <div className={styles.video_container}>
          <iframe
            className={classNames(styles.video, { [styles.only]: !hasBody })}
            title="Youtube"
            width="100%"
            src={itemUrl + '?rel=0'}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      ) : (
        <a href={itemUrl} target="_blank" rel="noopener noreferrer">
          <div className={styles.imageContainer}>
            <img
              className={classNames(styles.image, { [styles.only]: !hasBody })}
              src={imageUrl}
              alt={alt}
            />
          </div>
        </a>
      )
    ) : (
      <div className={styles.imageContainer}>
        <img
          className={classNames(styles.image, { [styles.only]: !hasBody })}
          src={imageUrl}
          alt={alt}
        />
      </div>
    )
  ) : null;

export default Image;
