import { Button } from '@botfabrik/engine-domain';
import classNames from 'classnames';
import messageStyle from '../../../Message.module.scss';
import Buttons from './Buttons';
import Subtitle from './Subtitle';
import Text from './Text';
import Title from './Title';

type Props = {
  title?: string;
  text?: string;
  subtitle: string;
  buttons: Button[];
  onButtonClick: (button: Button) => void;
  onLinkClick: (href: string) => void;
};

const Body = ({
  title,
  text,
  subtitle,
  buttons,
  onButtonClick,
  onLinkClick,
}: Props) => {
  const hasContent =
    title?.length || text?.length || subtitle?.length || buttons?.length;
  return hasContent ? (
    <div className={classNames(messageStyle.bubbleContent)}>
      <Title title={title} />
      <Text text={text} onLinkClick={onLinkClick} />
      <Subtitle subtitle={subtitle} onLinkClick={onLinkClick} />
      <Buttons buttons={buttons} onButtonClick={onButtonClick} />
    </div>
  ) : null;
};

export default Body;
