import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useRef, useState } from 'react';
import globalStyles from '../../../Global.module.scss';
import useTranslation from '../../../locales/useTranslation';
import bottomSheetStyles from '../../BottomSheet.module.scss';
import styles from './TranscriptMail.module.scss';

interface Props {
  visible: boolean;
  methods: {
    pdf: boolean;
    email: boolean;
  };
  onEmailExport: (email: string) => void;
  onPdfExport: () => void;
  onClose: () => void;
}

const TranscriptMail = ({
  visible,
  methods,
  onEmailExport,
  onPdfExport,
  onClose,
}: Props) => {
  const translate = useTranslation();
  const [internalVisible, setInternalVisible] = useState<boolean | undefined>(
    undefined
  );
  const [submitted, setSubmitted] = useState(false);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (visible) {
      inputRef.current?.focus();
      setSubmitted(false);
      setGeneratingPdf(false);
      setInternalVisible(visible);
    } else if (internalVisible === true) {
      setInternalVisible(false);
    }
  }, [visible, internalVisible]);

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email && email.length > 0) {
      onEmailExport(email);

      setSubmitted(true);
      setTimeout(onClose, 3 * 1000);
    }
  };

  const handleOnEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value.trim();
    const emailIsValide = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );
    setEmail(emailIsValide ? email : '');
  };

  const handleGeneratePdf = () => {
    setGeneratingPdf(true);
    onPdfExport();
    setTimeout(onClose, 3 * 1000);
  };

  return (
    <div
      className={classNames(styles.container, bottomSheetStyles.bottomSheet, {
        [bottomSheetStyles.bottomSheetOpen]: internalVisible === true,
        [bottomSheetStyles.bottomSheetClose]: internalVisible === false,
      })}
    >
      {methods.pdf && (
        <>
          <h2>{translate('export-transcript.pdf.title')}</h2>
          <Markdown className={styles.description}>
            {translate('export-transcript.pdf.description') || ''}
          </Markdown>
          <div className={styles.actions}>
            <button
              type="button"
              className={classNames(
                globalStyles.button,
                globalStyles.buttonDefault
              )}
              onClick={onClose}
            >
              {translate('export-transcript.pdf.cancel')}
            </button>
            <button
              className={classNames(
                globalStyles.button,
                globalStyles.buttonPrimary
              )}
              onClick={handleGeneratePdf}
              disabled={generatingPdf}
            >
              {translate('export-transcript.pdf.download')}
            </button>
          </div>
        </>
      )}
      {methods.email && (
        <>
          <h2>{translate('export-transcript.email.title')}</h2>
          <Markdown className={styles.description}>
            {translate('export-transcript.email.description') || ''}
          </Markdown>

          <form onSubmit={handleOnSubmit} className={styles.formContainer}>
            <div>
              <input
                disabled={submitted}
                ref={inputRef}
                type="email"
                placeholder={translate('export-transcript.email.placeholder')}
                className={styles.emailInput}
                onChange={handleOnEmailChange}
              />
              {email?.length === 0 ? (
                <div className={styles.validationError}>
                  {translate('export-transcript.email.invalid')}
                </div>
              ) : null}
            </div>
            <div className={styles.actions}>
              <button
                type="button"
                className={classNames(
                  globalStyles.button,
                  globalStyles.buttonDefault
                )}
                onClick={onClose}
              >
                {translate('export-transcript.email.cancel')}
              </button>
              <button
                type="submit"
                disabled={
                  submitted || email === undefined || email.length === 0
                }
                className={classNames(
                  globalStyles.button,
                  globalStyles.buttonPrimary
                )}
              >
                {translate('export-transcript.email.send')}
              </button>
            </div>
          </form>

          {submitted && (
            <div className={styles.submitted}>
              {translate('export-transcript.email.success')}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TranscriptMail;
