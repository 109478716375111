import Text from '../Text';
import styles from './DetailMessage.module.scss';

type Props = {
  subtitle: string | undefined;
  onLinkClick: (href: string) => void;
};

const Subtitle = ({ subtitle, onLinkClick }: Props) =>
  subtitle ? (
    <div className={styles.subtitle}>
      <Text text={subtitle} onLinkClick={onLinkClick} />
    </div>
  ) : null;

export default Subtitle;
