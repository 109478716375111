import classNames from 'classnames';
import React from 'react';
import styles from './AudioRecorder.module.scss';

const BUFFER_SIZE = 2048;
const OUTPUT_SAMPLE_RATE = 16000;

/**
 * The implementation of the AudioRecorder was inspired by the following blogpost:
 * https://typedarray.org/from-microphone-to-wav-with-getusermedia-and-web-audio/
 */

interface Props {
  translate: (key: string) => string | undefined;
  onAudioRecorded: (blob: Blob) => void;
}

interface State {
  recordingState: string;
}

export default class AudioRecorder extends React.Component<Props, State> {
  private audioInput: MediaStreamAudioSourceNode | null = null;
  private volume: GainNode | null = null;
  private leftchannel: Float32Array[] = [];
  private recorder: ScriptProcessorNode | null = null;
  private recordingLength: number = 0;
  private sampleRate: number | null = null;
  private mediaStream: MediaStream | null = null;

  constructor(props: Props) {
    super(props);
    this.state = { recordingState: 'none' };

    this.triggerAudioRecording = this.triggerAudioRecording.bind(this);
    this.triggerAudioRecordingSuccess =
      this.triggerAudioRecordingSuccess.bind(this);
    this.initRecording = this.initRecording.bind(this);
    this.processRecordingInput = this.processRecordingInput.bind(this);
    this.handleClickSubmitAudio = this.handleClickSubmitAudio.bind(this);
    this.handleClickStopAudio = this.handleClickStopAudio.bind(this);
  }

  triggerAudioRecording() {
    this.setState({ recordingState: 'connecting' });
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(this.triggerAudioRecordingSuccess);
  }

  triggerAudioRecordingSuccess(stream: MediaStream) {
    this.mediaStream = stream;
    this.leftchannel.length = 0;
    this.recordingLength = 0;

    this.initRecording(stream);
  }

  initRecording(stream: MediaStream) {
    var AudioContext =
      window.AudioContext || (window as any).webkitAudioContext;
    var context = new AudioContext();
    // we query the context sample rate (varies depending on platforms)
    this.sampleRate = context.sampleRate;
    // creates a gain node which adjusts the volume on audio streams
    this.volume = context.createGain();
    // creates an audio node from the microphone incoming stream
    this.audioInput = context.createMediaStreamSource(stream);
    // connect the stream to the gain node
    this.audioInput.connect(this.volume);
    this.recorder = context.createScriptProcessor(BUFFER_SIZE, 1, 1);
    this.recorder.onaudioprocess = this.processRecordingInput;
    // we connect the recorder
    this.volume.connect(this.recorder);
    this.recorder.connect(context.destination);
  }

  processRecordingInput(e: AudioProcessingEvent) {
    if (this.state.recordingState === 'connecting') {
      this.setState({ recordingState: 'recording' });
    }
    if (this.state.recordingState !== 'none') {
      const left = e.inputBuffer.getChannelData(0);
      // we clone the samples
      this.leftchannel.push(new Float32Array(left));
      this.recordingLength += BUFFER_SIZE;
    }
  }

  handleClickSubmitAudio() {
    // we flat the left channel down
    const leftBuffer = mergeBuffers(this.leftchannel, this.recordingLength);
    const downsampledBuffer = downsampleBuffer(
      leftBuffer,
      this.sampleRate || 0
    );

    // we create our wav file
    const blob = createWav(downsampledBuffer);
    this.props.onAudioRecorded(blob);

    // For debugging purpose: Set link to state so you can display a button on the UI to download the audio file:
    // var url = (window.URL || window.webkitURL).createObjectURL(blob);
    // this.setState({url});

    this.handleClickStopAudio();
  }

  handleClickStopAudio() {
    this.setState({ recordingState: 'none' });
    this.audioInput?.disconnect();
    this.volume?.disconnect();
    this.recorder?.disconnect();
    if (this.mediaStream) {
      this.mediaStream?.getAudioTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    }
  }

  render() {
    return (
      <div className={styles.audioRecorder}>
        {this.state.recordingState === 'none' && (
          <button
            className={classNames(styles.recordButton, 'chat__button')}
            type="submit"
            aria-label={this.props.translate(
              'message-input.actions.start-audio-recording.label'
            )}
            data-title={this.props.translate(
              'message-input.actions.start-audio-recording.label'
            )}
            tabIndex={0}
            onClick={this.triggerAudioRecording}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </button>
        )}

        {this.state.recordingState === 'connecting' && (
          <svg
            className={styles.spinning}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        )}

        {this.state.recordingState === 'recording' && (
          <div className={styles.audioRecorder}>
            <button
              className={classNames(styles.cancelButton)}
              onClick={this.handleClickStopAudio}
              aria-label={this.props.translate(
                'message-input.actions.stop-audio-recording.label'
              )}
              tabIndex={0}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </button>
            <div className={styles.recorderIcon} />
            <button
              className={classNames(styles.submitButton)}
              type="submit"
              onClick={this.handleClickSubmitAudio}
              aria-label={this.props.translate(
                'message-input.actions.submit-audio-recording.label'
              )}
              tabIndex={0}
              autoFocus
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
              </svg>
            </button>
          </div>
        )}
      </div>
    );
  }
}

const createWav = (downsampledBuffer: Float32Array) => {
  const buffer = new ArrayBuffer(44 + downsampledBuffer.length * 2);
  const view = new DataView(buffer);

  // RIFF chunk descriptor
  writeUTFBytes(view, 0, 'RIFF');
  view.setUint32(4, 44 + downsampledBuffer.length * 2, true);
  writeUTFBytes(view, 8, 'WAVE');

  // FMT sub-chunk
  writeUTFBytes(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);

  // mono - 1 channel
  view.setUint16(22, 1, true);
  view.setUint32(24, OUTPUT_SAMPLE_RATE, true);
  view.setUint32(28, OUTPUT_SAMPLE_RATE * 4, true);
  view.setUint16(32, 4, true);
  view.setUint16(34, 16, true);

  // data sub-chunk
  writeUTFBytes(view, 36, 'data');
  view.setUint32(40, downsampledBuffer.length * 2, true);

  // write the PCM samples
  const lng = downsampledBuffer.length;
  let index = 44;
  const volume = 1;
  for (var i = 0; i < lng; i++) {
    view.setInt16(index, downsampledBuffer[i] * (0x7fff * volume), true);
    index += 2;
  }

  // our final binary blob
  return new Blob([view], { type: 'audio/wav' });
};

const writeUTFBytes = (view: DataView, offset: number, val: string) => {
  for (var i = 0; i < val.length; i++) {
    view.setUint8(offset + i, val.charCodeAt(i));
  }
};

const mergeBuffers = (
  channelBuffer: Float32Array[],
  recordingLength: number
) => {
  const result = new Float32Array(recordingLength);
  let offset = 0;
  const lng = channelBuffer.length;
  for (var i = 0; i < lng; i++) {
    const buffer = channelBuffer[i];
    result.set(buffer, offset);
    offset += buffer.length;
  }
  return result;
};

const downsampleBuffer = (buffer: Float32Array, sampleRate: number) => {
  if (OUTPUT_SAMPLE_RATE === sampleRate) {
    return buffer;
  }
  const sampleRateRatio = sampleRate / OUTPUT_SAMPLE_RATE;
  const newLength = Math.round(buffer.length / sampleRateRatio);
  const result = new Float32Array(newLength);
  let offsetResult = 0;
  let offsetBuffer = 0;
  while (offsetResult < result.length) {
    let nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
    let accum = 0,
      count = 0;
    for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }
    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }
  return result;
};
