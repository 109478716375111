import classNames from 'classnames';
import styles from './Start.module.scss';

type Props = {
  name: string;
  label: string;
  defaultValue: string | undefined;
  required: boolean;
  onChange: (value: string) => void;
};

const Checkbox = ({ name, label, defaultValue, required, onChange }: Props) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.checked ? 'true' : 'false');
  };

  return (
    <label
      className={classNames(styles.checkbox, 'chat__startScreen__checkbox')}
      htmlFor={name}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        defaultChecked={defaultValue === 'true'}
        required={required}
        onChange={handleOnChange}
      />
      <div>{label}</div>
    </label>
  );
};

export default Checkbox;
