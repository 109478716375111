import { QuickReply } from '@botfabrik/engine-domain';
import classNames from 'classnames';
import globalStyles from '../../../../Global.module.scss';
import styles from './QuickReplies.module.scss';

type Props = {
  quickReplies: QuickReply[];
  onQuickReplyClick: (reply: QuickReply) => void;
};

const QuickReplies = ({ quickReplies, onQuickReplyClick }: Props) =>
  quickReplies.length ? (
    <div className={classNames(styles.quickReplies, 'chat__quickReplies')}>
      {quickReplies.map((reply) => (
        <button
          key={reply.label}
          tabIndex={0}
          className={classNames(
            styles.quickReply,
            globalStyles.button,
            'chat__button',
            'chat__quickReply'
          )}
          onClick={() => onQuickReplyClick(reply)}
        >
          {reply.label}
        </button>
      ))}
    </div>
  ) : null;

export default QuickReplies;
