import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import useTranslation from '../../../../../locales/useTranslation';
import styles from '../../../Message.module.scss';

interface Props {
  isFirstMessageBySender: boolean;
  isLastMessageBySender: boolean;
  children: ReactNode;
}

const UserMessage = forwardRef<HTMLLIElement, Props>(
  ({ isFirstMessageBySender, isLastMessageBySender, children }, ref) => {
    const translate = useTranslation();
    return (
      <li
        ref={ref}
        className={classNames(
          styles.message,
          styles.messageGuest,
          isFirstMessageBySender && styles.messageGuestFirst,
          isLastMessageBySender && styles.messageGuestLast,
          'chat__bubbleContainer',
          'chat__guestBubbleContainer',
          isFirstMessageBySender && 'chat__bubbleContainer--first',
          isLastMessageBySender && 'chat__bubbleContainer--last'
        )}
      >
        <div
          className={classNames(
            styles.bubble,
            styles.bubbleRight,
            'chat__bubble',
            'chat__guestBubble'
          )}
          tabIndex={0}
          aria-label={translate('guest-bubble.label')}
        >
          <div className={classNames(styles.rightText)}>{children}</div>
        </div>
      </li>
    );
  }
);

export default UserMessage;
