import classNames from 'classnames';
import useTranslation from '../../../../locales/useTranslation';
import styles from './BlockUserInput.module.scss';

type Props = {
  visible: boolean;
};

const BlockUserInput = ({ visible }: Props) => {
  const translate = useTranslation();

  return visible ? (
    <div className={classNames(styles.blockUserInput, 'chat__blockUserInput')}>
      {translate('block-user-input.title')}
    </div>
  ) : null;
};

export default BlockUserInput;
