import messageStyle from '../../../Message.module.scss';
import Text from '../Text';

type Props = {
  text: string;
  onLinkClick: (href: string) => void;
};
const TextMessage = ({ text, onLinkClick }: Props) => (
  <div className={messageStyle.bubbleContent}>
    <Text text={text} onLinkClick={onLinkClick} />
  </div>
);

export default TextMessage;
