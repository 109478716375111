import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import useTranslation from '../../../../locales/useTranslation';
import styles from './GeneralConditions.module.scss';

const HEADER_KEY = 'general-conditions.header';
const BODY_KEY = 'general-conditions.body';
const ACCEPT_KEY = 'general-conditions.accept-conditions-note';

const GeneralConditions = () => {
  const translate = useTranslation();
  const [showDetails, setShowDetails] = useState(false);

  const toggleShowDetails = () => setShowDetails(!showDetails);

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      setShowDetails(true);
    } else if (
      event.key === 'ArrowUp' ||
      event.key === 'ArrowLeft' ||
      event.key === 'Escape'
    ) {
      setShowDetails(false);
    } else if (
      event.key === 'Enter' ||
      event.key === 'Space' ||
      event.key === ' '
    ) {
      toggleShowDetails();
    }
  };

  const headerText = translate(HEADER_KEY);
  const acceptText = translate(ACCEPT_KEY);

  return (
    <div
      className={classNames(
        styles.generalConditionsContainer,
        'chat__generalConditions'
      )}
    >
      {!!headerText?.length && (
        <div className={styles.generalConditions}>
          <div
            role="button"
            onClick={toggleShowDetails}
            onKeyDown={handleOnKeyDown}
            tabIndex={0}
            className={classNames(
              styles.generalConditionsHeader,
              'chat__generalConditions__header'
            )}
            aria-controls="general-conditions-body"
            aria-expanded={showDetails ? 'true' : 'false'}
            aria-pressed={showDetails ? 'true' : 'false'}
          >
            <Markdown>{headerText}</Markdown>
            {showDetails ? <FaAngleUp /> : <FaAngleDown />}
          </div>
          <div
            className={classNames(
              styles.generalConditionsBody,
              'chat__generalConditions__body'
            )}
            id="general-conditions-body"
            role="region"
            tabIndex={-1}
          >
            {showDetails && <Markdown>{translate(BODY_KEY) || ''}</Markdown>}
          </div>
        </div>
      )}
      {!!acceptText?.length && (
        <div
          className={classNames(
            styles.generalConditionsAccept,
            'chat__generalConditions__accept'
          )}
          tabIndex={0}
        >
          <Markdown>{translate(ACCEPT_KEY) || ''}</Markdown>
        </div>
      )}
    </div>
  );
};

export default GeneralConditions;
