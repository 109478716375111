import classNames from 'classnames';
import styles from './TypingIndicator.module.scss';

type Props = {
  isTyping?: boolean;
};

const TypingIndicator = ({ isTyping = false }: Props) => (
  <div className={styles.ticontainer}>
    <div className={styles.tiblock}>
      {isTyping ? (
        <>
          <div
            className={classNames(styles.tidot, 'chat__typingIndicator')}
          ></div>
          <div
            className={classNames(styles.tidot, 'chat__typingIndicator')}
          ></div>
          <div
            className={classNames(styles.tidot, 'chat__typingIndicator')}
          ></div>
        </>
      ) : null}
    </div>
  </div>
);

export default TypingIndicator;
