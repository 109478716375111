import classNames from 'classnames';
import { useState } from 'react';
import styles from './RateInput.module.scss';
import Star from './Star';

const STAR_COUNT = 5;

interface Props {
  value: number;
  size: 'small' | 'large';
  readonly?: boolean;
  onChange?: (value: number) => void;
}

const Stars = ({ value, size, readonly = false, onChange }: Props) => {
  const [hoverValue, setHoverValue] = useState<number | undefined>(undefined);

  const handleOnMouseOut = () => {
    setHoverValue(undefined);
  };

  const handleOnStarHover = (starIndex: number) => () => {
    if (!readonly) {
      const hoverValue = starIndex + 1;
      setHoverValue(hoverValue);
    }
  };

  const handleOnStarClick = (starIndex: number) => () => {
    if (onChange && !readonly) {
      if (starIndex === 0 && value === 1) {
        onChange(0);
      } else {
        onChange(starIndex + 1);
      }
      setHoverValue(undefined);
    }
  };

  return (
    <ul
      tabIndex={0}
      role="radiogroup"
      className={classNames(
        styles.stars,
        {
          [styles.starsSmall]: size === 'small',
          [styles.starsLarge]: size === 'large',
        },
        'chat__conversationRating__stars',
        {
          chat__conversationRating__stars__small: size === 'small',
          chat__conversationRating__stars__large: size === 'large',
        }
      )}
      onMouseOut={handleOnMouseOut}
    >
      {Array(STAR_COUNT)
        .fill(0)
        .map((_, index) => (
          <Star
            key={index}
            value={hoverValue === undefined ? value : hoverValue}
            index={index}
            count={STAR_COUNT}
            readonly={readonly}
            onHover={handleOnStarHover(index)}
            onClick={handleOnStarClick(index)}
          />
        ))}
    </ul>
  );
};

export default Stars;
