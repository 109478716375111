import { combineReducers } from 'redux';
import backendConnectionReducer from './backendConnectionReducer';
import chatReducer from './chatReducer';
import intlReducer from './intlReducer';
import startScreenReducer from './startScreenReducer';

const rootReducer = combineReducers({
  chat: chatReducer,
  startScreen: startScreenReducer,
  connection: backendConnectionReducer,
  intl: intlReducer,
});

export default rootReducer;
