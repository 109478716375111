import { Button } from '@botfabrik/engine-domain';
import classNames from 'classnames';
import globalStyles from '../../../../../Global.module.scss';
import styles from './DetailMessage.module.scss';

type Props = {
  buttons: Button[];
  onButtonClick: (button: Button) => void;
};

const Buttons = ({ buttons, onButtonClick }: Props) => (
  <div className={classNames(styles.buttons, 'chat__bubbleButtons')}>
    {buttons.map((button, index) => (
      <button
        key={index}
        className={classNames(
          styles.button,
          globalStyles.button,
          'chat__bubbleButton'
        )}
        onClick={() => onButtonClick(button)}
      >
        {button.title}
      </button>
    ))}
  </div>
);

export default Buttons;
