import { receiveTextMessageFromGuest } from '@botfabrik/engine-domain';
import type { AppDispatch, RootState } from '../store';
import handleQuickReplyClick from './handleQuickReplyClick';

/**
 * Informiere den Store, dass der Benutzer eine neue Nachricht gesendet hat.
 */
const sendMessageFromUser = (text: string) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const { quickReplies } = getState().chat;
    const matchedQuickReply = quickReplies.find(
      (qr) =>
        text.toLocaleLowerCase().indexOf(qr.label.toLocaleLowerCase()) > -1
    );
    if (matchedQuickReply) {
      return dispatch(
        handleQuickReplyClick({ ...matchedQuickReply, label: text })
      );
    } else {
      return dispatch(receiveTextMessageFromGuest(text));
    }
  };
};

export default sendMessageFromUser;
