const getClientName = () => {
  return extractClientNameFromPathname(window.location.pathname);
};

export default getClientName;

const extractClientNameFromPathname = (pathname: string) => {
  const pathElements = pathname.split('/');
  const endsWithSlash = pathname.endsWith('/');
  return pathElements[pathElements.length - (endsWithSlash ? 2 : 1)];
};
