import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../locales';
import { RootState } from '../store';

export const counterSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    updateIntl: (
      state,
      action: PayloadAction<{
        language: string;
        messages: Record<string, string>;
      }>
    ) => {
      state.locale = action.payload.language;
      state.messages = action.payload.messages;
    },
  },
});

export const { updateIntl } = counterSlice.actions;
export const selectLocale = (state: RootState) => state.intl.locale;
export const selectTranslation = (state: RootState) => state.intl.messages;

export default counterSlice.reducer;
