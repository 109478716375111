import styles from './DetailMessage.module.scss';

type Props = {
  title: string | undefined;
};

const Title = ({ title }: Props) =>
  title ? <h1 className={styles.title}>{title}</h1> : null;

export default Title;
