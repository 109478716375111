import {
  ActionTypes,
  QuickReply,
  receiveTextMessageWithoutAnalysisFromGuest,
} from '@botfabrik/engine-domain';
import type { AppDispatch } from '../store';

const handleQuickReplyClick = (reply: QuickReply) => {
  return (dispatch: AppDispatch) => {
    if (reply.payload.type !== ActionTypes.MESSAGE_FROM_GUEST) {
      dispatch(
        receiveTextMessageWithoutAnalysisFromGuest(reply.label, reply.payload)
      );
    }
    return dispatch(reply.payload);
  };
};

export default handleQuickReplyClick;
