import { useIntl } from 'react-intl';

const useTranslation = () => {
  const intl = useIntl();

  const translate = (id: string): string | undefined => {
    const translation = intl.formatMessage({ id });
    return translation === id ? undefined : translation;
  };

  return translate;
};

export default useTranslation;
