import { QuickReply } from '@botfabrik/engine-domain';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { ConversationRatingState } from '../../../types';
import styles from './InputContainer.module.scss';
import BlockUserInput from './block-user-input';
import ConversationRating from './conversation-rating';
import Input from './input';
import QuickReplies from './quick-replies';

const MIN_USER_BLOCKED_TIME = 5_000;
const MIN_TIME_BETWEEN_USER_REQUESTS = 1_000;

interface Props {
  numberOfMessagesFromGuest: number;
  isBotTyping: boolean;
  quickReplies: QuickReply[];
  speechSupport: boolean;
  transcriptExportEnabled: boolean;
  conversationRating: ConversationRatingState;
  showRatingOverlay: boolean;
  setShowRatingOverlay: (show: boolean) => void;
  onTextInput: (text: string) => void;
  onRate: (rating: number, description: string) => void;
  onQuickReplyClick: (reply: QuickReply) => void;
  onAudioRecorded: (blob: Blob) => void;
  onOpenTranscriptExportModal: () => void;
}

const UserInput = ({
  isBotTyping,
  quickReplies,
  speechSupport,
  transcriptExportEnabled,
  conversationRating,
  numberOfMessagesFromGuest,
  showRatingOverlay,
  setShowRatingOverlay,
  onTextInput,
  onRate,
  onQuickReplyClick,
  onAudioRecorded,
  onOpenTranscriptExportModal,
}: Props) => {
  const [blockUserInput, setBlockUserInput] = useState(false);
  const blockUserInputStartAt = useRef<number>();
  const lastUserInputAt = useRef<number>(0);

  useEffect(() => {
    if (blockUserInput && !isBotTyping) {
      const displayTime = blockUserInputStartAt.current
        ? Date.now() - blockUserInputStartAt.current
        : 0;

      if (displayTime > MIN_USER_BLOCKED_TIME) {
        setBlockUserInput(false);
      } else {
        setTimeout(() => {
          setBlockUserInput(false);
        }, MIN_USER_BLOCKED_TIME - displayTime);
      }
    }
  }, [blockUserInput, isBotTyping]);

  useEffect(() => {
    if (blockUserInput) {
      blockUserInputStartAt.current = Date.now();
    }
  }, [blockUserInput]);

  const handleOnTextInput = (text: string): boolean => {
    const lastUserInputWasShortlyBefore =
      Date.now() - lastUserInputAt.current < MIN_TIME_BETWEEN_USER_REQUESTS;
    if (isBotTyping || lastUserInputWasShortlyBefore) {
      setBlockUserInput(true);
      return false;
    } else {
      onTextInput(text);
      lastUserInputAt.current = Date.now();
      return true;
    }
  };

  return (
    <div className={classNames(styles.inputContainer)}>
      <QuickReplies
        quickReplies={quickReplies}
        onQuickReplyClick={onQuickReplyClick}
      />

      <BlockUserInput visible={blockUserInput} />

      {!blockUserInput && (
        <ConversationRating
          conversationRating={conversationRating}
          numberOfMessagesFromGuest={numberOfMessagesFromGuest}
          onRate={onRate}
          showRatingOverlay={showRatingOverlay}
          setShowRatingOverlay={setShowRatingOverlay}
        />
      )}

      <Input
        onTextInput={handleOnTextInput}
        onAudioRecorded={onAudioRecorded}
        speechSupport={speechSupport}
        onOpenTranscriptExportModal={onOpenTranscriptExportModal}
        transcriptExportEnabled={transcriptExportEnabled}
        disabled={blockUserInput}
      />
    </div>
  );
};

export default UserInput;
