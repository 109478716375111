import MarkdownText from '../MarkdownText';

type Props = {
  text: string | undefined;
  onLinkClick: (href: string) => void;
};

const Text = ({ text, onLinkClick }: Props) =>
  text ? (
    <div>
      <MarkdownText markdown={text} onLinkClick={onLinkClick} />
    </div>
  ) : null;

export default Text;
